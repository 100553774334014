import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
import * as _stream2 from "stream";
var _stream = "default" in _stream2 ? _stream2.default : _stream2;
var exports = {};
const inherits = _util.inherits;
const ReadableStream = _stream.Readable;
function PartStream(opts) {
  ReadableStream.call(this, opts);
}
inherits(PartStream, ReadableStream);
PartStream.prototype._read = function (n) {};
exports = PartStream;
export default exports;