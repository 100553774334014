import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
import * as _textDecoding2 from "text-decoding";
var _textDecoding = "default" in _textDecoding2 ? _textDecoding2.default : _textDecoding2;
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
let TextDecoder;
try {
  TextDecoder = _util.TextDecoder;
} catch (e) {}
const {
  TextDecoder: PolyfillTextDecoder,
  getEncoding
} = _textDecoding;

// Node has always utf-8
const textDecoders = new Map();
if (TextDecoder) {
  textDecoders.set("utf-8", new TextDecoder("utf-8"));
} else {
  textDecoders.set("utf-8", new PolyfillTextDecoder("utf-8"));
}
textDecoders.set("utf8", textDecoders.get("utf-8"));
function decodeText(text, textEncoding, destEncoding) {
  if (text) {
    if (textDecoders.has(destEncoding)) {
      try {
        return textDecoders.get(destEncoding).decode(Buffer.from(text, textEncoding));
      } catch (e) {}
    } else {
      try {
        textDecoders.set(destEncoding, new TextDecoder(destEncoding));
        return textDecoders.get(destEncoding).decode(Buffer.from(text, textEncoding));
      } catch (e) {
        if (getEncoding(destEncoding)) {
          try {
            textDecoders.set(destEncoding, new PolyfillTextDecoder(destEncoding));
            return textDecoders.get(destEncoding).decode(Buffer.from(text, textEncoding));
          } catch (e) {}
        }
      }
    }
  }
  return text;
}
function decodeTextPolyfill(text, textEncoding, destEncoding) {
  if (text) {
    if (textDecoders.has(destEncoding)) {
      try {
        return textDecoders.get(destEncoding).decode(Buffer.from(text, textEncoding));
      } catch (e) {}
    } else {
      if (getEncoding(destEncoding)) {
        try {
          textDecoders.set(destEncoding, new PolyfillTextDecoder(destEncoding));
          return textDecoders.get(destEncoding).decode(Buffer.from(text, textEncoding));
        } catch (e) {}
      }
    }
  }
  return text;
}
exports = TextDecoder ? decodeText : decodeTextPolyfill;
export default exports;