var exports = {};
exports = function basename(path) {
  if (typeof path !== "string") {
    return "";
  }
  for (var i = path.length - 1; i >= 0; --i) {
    // eslint-disable-line no-var
    switch (path.charCodeAt(i)) {
      case 47: // '/'
      case 92:
        // '\'
        path = path.slice(i + 1);
        return path === ".." || path === "." ? "" : path;
    }
  }
  return path === ".." || path === "." ? "" : path;
};
export default exports;