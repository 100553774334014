import * as _stream2 from "stream";
var _stream = "default" in _stream2 ? _stream2.default : _stream2;
import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
import _Dicer from "../deps/dicer/lib/Dicer";
import _multipart from "./types/multipart";
import _urlencoded from "./types/urlencoded";
import _parseParams from "./utils/parseParams";
var exports = {};
const WritableStream = _stream.Writable;
const {
  inherits
} = _util;
const Dicer = _Dicer;
const MultipartParser = _multipart;
const UrlencodedParser = _urlencoded;
const parseParams = _parseParams;
function Busboy(opts) {
  if (!(this instanceof Busboy)) {
    return new Busboy(opts);
  }
  if (typeof opts !== "object") {
    throw new TypeError("Busboy expected an options-Object.");
  }
  if (typeof opts.headers !== "object") {
    throw new TypeError("Busboy expected an options-Object with headers-attribute.");
  }
  if (typeof opts.headers["content-type"] !== "string") {
    throw new TypeError("Missing Content-Type-header.");
  }
  const {
    headers,
    ...streamOptions
  } = opts;
  this.opts = {
    autoDestroy: false,
    ...streamOptions
  };
  WritableStream.call(this, this.opts);
  this._done = false;
  this._parser = this.getParserByHeaders(headers);
  this._finished = false;
}
inherits(Busboy, WritableStream);
Busboy.prototype.emit = function (ev) {
  if (ev === "finish") {
    if (!this._done) {
      this._parser && this._parser.end();
      return;
    } else if (this._finished) {
      return;
    }
    this._finished = true;
  }
  WritableStream.prototype.emit.apply(this, arguments);
};
Busboy.prototype.getParserByHeaders = function (headers) {
  const parsed = parseParams(headers["content-type"]);
  const cfg = {
    defCharset: this.opts.defCharset,
    fileHwm: this.opts.fileHwm,
    headers,
    highWaterMark: this.opts.highWaterMark,
    isPartAFile: this.opts.isPartAFile,
    limits: this.opts.limits,
    parsedConType: parsed,
    preservePath: this.opts.preservePath
  };
  if (MultipartParser.detect.test(parsed[0])) {
    return new MultipartParser(this, cfg);
  }
  if (UrlencodedParser.detect.test(parsed[0])) {
    return new UrlencodedParser(this, cfg);
  }
  throw new Error("Unsupported Content-Type.");
};
Busboy.prototype._write = function (chunk, encoding, cb) {
  this._parser.write(chunk, cb);
};
exports = Busboy;
exports.default = Busboy;
exports.Busboy = Busboy;
exports.Dicer = Dicer;
export default exports;
const _Busboy = exports.Busboy,
  _Dicer2 = exports.Dicer;
export { _Busboy as Busboy, _Dicer2 as Dicer };